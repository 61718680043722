<template></template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("notifications", ["error"]),
    ...mapActions(["login"])
  },
  created() {
    if (Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object){
      this.$router.push("/login");
      return;
    }

    this.login(this.$route.query)
        .then(() => {
          this.$emit("login");
          this.$router.push("/");
        })
        .catch(error => {
          this.error(error.message);
        });
  }
};
</script>

